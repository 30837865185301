











import {User} from "@/request/customer/User";
import {MemberSet} from "@/request/customer/MemberSet";

export default {
    data() {
        let self: any =this;
        return {
            modal: User,
            search: [
                {
                    type: 'Input',
                    label: '昵称',
                    key: 'nickName',
                    width: '120px',
                    clearable: true,
                    placeholder: '请填写昵称'
                },
                {
                    type: 'Input',
                    label: '电话',
                    key: 'phone',
                    width: '140px',
                    clearable: true,
                    placeholder: '请填写电话'
                },
                {
                    type: 'Select',
                    label: '会员等级',
                    key: 'lv',
                    clearable: true,
                    width: '120px',
                    items() {
                        return new Promise(resolve => {
                            MemberSet.getAll().then((body: any) => {
                                let data: any = body.data;
                                resolve(data.map((j: any) => {
                                    return {
                                        label: j.name,
                                        value: j.lv
                                    }
                                }));
                            })
                        })
                    }
                },
                {
                    type: 'DatePicker',
                    pickerType: 'datetimerange',
                    format: 'yyyy-MM-dd HH:mm',
                    label: '注册时间',
                    width: '220px',
                    placeholder: '请选择注册时间'
                }
            ],
            columns: [
                {
                    title: '昵称',
                    key: 'nickName',
                    minWidth: 120
                },
                {
                    title: '头像',
                    key: 'avatarUrl',
                    minWidth: 80,
                    render: (h: any, params: any) => {
                        return h('img', {
                            style: {
                                borderRadius: '3px',
                                height: '40px',
                                width: '40px',
                                marginTop: '5px'
                            },
                            attrs: {
                                src: params.row.avatarUrl
                            }
                        })
                    }
                },
                {
                    title: '性别',
                    key: 'gender',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', p.row.gender===1?'男':p.row.gender===2?'女': '未知')
                },
                {
                    title: '电话',
                    key: 'phone',
                    minWidth: 140
                },
                {
                    title: '总积分',
                    key: 'totalIntegral',
                    minWidth: 100
                },
                {
                    title: '使用积分',
                    key: 'useIntegral',
                    minWidth: 100
                },
                {
                    title: '剩余积分',
                    key: 'curIntegral',
                    minWidth: 100
                },
                {
                    title: '成长值',
                    key: 'growth',
                    minWidth: 100
                },
                {
                    title: '会员等级',
                    key: 'memberLv',
                    minWidth: 100
                },
                {
                    title: '会员等级',
                    key: 'memberName',
                    minWidth: 120
                },
                {
                    title: '交易笔数',
                    key: 'transNum',
                    minWidth: 100
                },
                {
                    title: '消费总金额',
                    key: 'useFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', (p.row.useFee / 100).toFixed(2))
                },
                {
                    title: '最后登陆时间',
                    key: 'lastLoginTime',
                    minWidth: 180
                },
                {
                    title: '注册时间',
                    key: 'createTime',
                    minWidth: 180
                }
            ]
        }
    },
    created() {
    },
    methods: {
    }
}
